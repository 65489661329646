<template>
    <div id="footer" class="container">
        <el-row :gutter="40" type="flex">
            <el-col :xs="24" :sm="24" :md="12" class="contacts">
                <h3>Find Us</h3>
                <ul>
                    <li>
                        <a href="tel:+61412419607"
                            ><i class="el-icon-mobile-phone" />
                            <span>+61 412 419 607</span></a
                        >
                    </li>
                    <li>
                        <a href="mailto:info@metropolitanarborist.com.au"
                            ><i class="el-icon-message" />
                            <span>info@metropolitanarborist.com.au</span></a
                        >
                    </li>
                    <li>
                        <a
                            href="http://maps.google.com/?q=1/322 Huntingdale Rd Mount Waverley, Victoria 3149"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i class="el-icon-place" />
                            <span
                                >1/322 Huntingdale Rd, <br />Mount Waverley,
                                Victoria 3149
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="/contact" target="_blank">
                            <i class="el-icon-time" />
                            <span> Monday to Friday - 7:30am to 3:30pm </span>
                        </a>
                    </li>
                </ul>
                <div class="socials">
                    <a
                        href="https://www.facebook.com/MetropolitanArborist"
                        target="_blank"
                        class="el-button el-button--primary is-plain"
                        rel="noreferrer"
                    >
                        <img
                            data-src="~/assets/images/facebook.svg"
                            alt="Metropolitan Arborist on Facebook"
                            class="lazyload"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/ilcoddu83/"
                        target="_blank"
                        class="el-button el-button--primary is-plain"
                        rel="noreferrer"
                    >
                        <img
                            data-src="~/assets/images/instagram.svg"
                            alt="Metropolitan Arborist"
                            class="lazyload"
                        />
                    </a>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" class="nav-links">
                <ul>
                    <li>
                        <nuxt-link to="/our-experience">
                            Our Experience
                        </nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/blog">Blog</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/work-with-us">Work With Us</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/contact">Contact</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/terms-conditions">
                            Terms &amp; Conditions
                        </nuxt-link>
                    </li>
                </ul>
                <p id="copy">
                    &copy; {{ new Date().getFullYear() }} Metropolitan Arborist.
                    ABN 45775507290
                </p>
            </el-col>
            <div
                :class="backtotop ? 'show' : 'hide'"
                class="backtotop"
                @click="scrollTop"
            >
                ↑
            </div>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            backtotop: false
        }
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 900) {
                this.backtotop = true
            } else {
                this.backtotop = false
            }
        },
        scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#footer {
    margin-top: 4em;
    padding-bottom: 2em;
    background-image: url('~assets/images/ovale.svg');
    background-repeat: no-repeat;
    background-size: auto 214%;
    background-position: 112% 69%;
}

.el-row {
    flex-wrap: wrap;

    .el-col {
        position: relative;
        min-height: 100%;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        font-size: 90%;
    }

    li,
    a {
        color: $body-light;
    }
}

h3 {
    margin-top: 0;
    margin-bottom: 1.5em;
    color: $body;
}

.contacts {
    li {
        margin-top: 1em;
    }

    i {
        margin-right: 1em;
        color: $primary;
    }

    a {
        display: flex;
        align-items: baseline;
    }

    span {
        line-height: initial;
    }
}

.nav-links {
    ul {
        text-align: right;
        margin-top: 2em;
        li {
            padding: 0.15em 0;
        }
    }

    a {
        &:hover {
            color: $primary;
        }
    }
}

.el-button {
    i {
        padding-left: 0.5em;
    }
}

#copy {
    font-size: 80%;
    text-align: right;
    margin-top: 3em;
}

.socials {
    display: inline-flex;
    margin-top: 0.5em;

    img {
        width: 60px;
    }

    .el-button {
        padding: 0;
        border: none;
    }
}

.backtotop {
    position: fixed;
    bottom: 0;
    padding: 0.625rem 1.25rem;
    font-size: 1.4rem;
    background: #e7f7ef;
    border: 1px solid #fff;
    display: none;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    &.show {
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    #footer {
        margin-top: 2em;
        padding-bottom: 1em;
        background-image: none;
    }

    .nav-links {
        ul {
            display: none;
            margin-top: 2em;
        }

        #copy {
            text-align: center;
            display: block;
        }
    }

    .contacts {
        border-bottom: 1px solid $linen;
        padding-bottom: 2em;
    }

    .socials {
        margin-top: 2em;
    }

    .backtotop {
        display: none;

        &.show {
            display: none;
        }
    }
}

@media only screen and (max-width: 767px) {
    .el-row {
        margin-left: -10px !important;
        margin-right: -10px !important;

        .el-col {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    .backtotop {
        display: none;

        &.show {
            display: none;
        }
    }
}
</style>
