<template>
    <div :class="fixed ? 'header fixed' : 'header'">
        <div class="container">
            <div class="flex-container">
                <div>
                    <nuxt-link to="/">
                        <img
                            id="logo"
                            data-src="~/assets/images/logo.png?webp"
                            alt="Metropolitan Arborist logo"
                            class="lazyload"
                            type="image/webp"
                        />
                    </nuxt-link>
                </div>

                <div>
                    <div class="hamburger" @click="openDrawer">
                        <p>MENU</p>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            fixed: false
        }
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 150) {
                this.fixed = true
            } else {
                this.fixed = false
            }
        },
        openDrawer() {
            const flag = !this.$store.state.general.nav
            this.$store.commit('general/toggle_nav', flag)
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    padding-top: 2em;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    transition: 0.2s ease-in-out;

    &.fixed {
        background: $white;
        padding: 0.5em 0;
        -webkit-box-shadow: 0px 0px 12px 0px rgba($black, 0.1);
        -moz-box-shadow: 0px 0px 12px 0px rgba($black, 0.1);
        box-shadow: 0px 0px 12px 0px rgba($black, 0.1);

        #logo {
            width: 300px;
            height: 46.7px;
        }

        .call {
            h2 {
                font-size: 1.3em;
            }
        }
    }
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#logo {
    width: 345px;
    height: 53.7px;
}

.call {
    display: inline-block;
    vertical-align: middle;
    padding-right: 4em;

    span {
        display: inline-block;
        color: $primary;
        font-family: 'Montserrat', sans-serif;
    }

    h2 {
        margin: 3px 0 0 0;
        letter-spacing: 0.5px;

        a {
            color: $heading;
        }
    }
}

.hamburger {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 0.35em;

    span {
        display: table;
        width: 36px;
        height: 4px;
        border-radius: 1px;
        background: $heading;
        margin: 0 0 5px 0;
        transition: 0.3s ease-in-out;
    }

    p {
        font-size: 0.65em;
        margin: 0 0 0.25em -2px;
        letter-spacing: 1.8px;
    }

    &:hover {
        span {
            background: $secondary;
        }
    }
}

@media only screen and (max-width: 991px) {
    .header {
        padding-top: 1em;
    }

    #logo {
        width: 300px;
        height: 46.7px;
    }

    .call {
        h2 {
            font-size: 1.3em;
        }
    }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 665px) {
    .header {
        padding-top: 1em;

        &.fixed {
            #logo {
                width: 240px;
                height: 46.7px;
            }
        }
    }

    #logo {
        width: 200px;
        height: 36.7px;
    }

    .call {
        display: none;
    }

    .hamburger {
        span {
            width: 30px;
            height: 3px;
        }

        p {
            font-size: 0.6em;
            margin: 0;
            letter-spacing: -0.3px;
        }
    }
}
</style>
