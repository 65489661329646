<template>
    <el-drawer
        :visible.sync="$store.state.general.nav"
        direction="rtl"
        :before-close="handleClose"
        size="350px"
        custom-class="drawer"
    >
        <div slot="title">
            <p>
                <i class="el-icon-mobile-phone" />
                Call Us
            </p>
            <h3>
                <a href="tel:+61412419607">+61 412 419 607</a>
            </h3>
        </div>

        <ul class="sub-list">
            <li>
                <nuxt-link to="/our-experience">
                    Our Experience
                </nuxt-link>
            </li>
            <li>
                <nuxt-link to="/blog">Blog</nuxt-link>
            </li>
            <li>
                <nuxt-link to="/work-with-us">Work With Us</nuxt-link>
            </li>
            <li>
                <nuxt-link to="/contact">Contact</nuxt-link>
            </li>
            <li>
                <nuxt-link to="/terms-conditions">
                    Terms &amp; Conditions
                </nuxt-link>
            </li>
        </ul>
        <div class="socials">
            <a
                href="https://www.facebook.com/MetropolitanArborist"
                target="_blank"
                class="el-button el-button--primary is-plain"
                rel="noreferrer"
            >
                <img
                    data-src="~/assets/images/facebook.svg"
                    alt="Metropolitan Arborist on Facebook"
                    class="lazyload"
                />
            </a>
            <a
                href="https://www.instagram.com/ilcoddu83/"
                target="_blank"
                class="el-button el-button--primary is-plain"
                rel="noreferrer"
            >
                <img
                    data-src="~/assets/images/instagram.svg"
                    alt="Metropolitan Arborist"
                    class="lazyload"
                />
            </a>
        </div>
    </el-drawer>
</template>

<script>
export default {
    name: 'SideNav',
    watch: {
        $route() {
            this.$store.commit('general/toggle_nav', false)
        }
    },
    methods: {
        handleClose() {
            const flag = !this.$store.state.general.nav
            this.$store.commit('general/toggle_nav', flag)
        }
    }
}
</script>

<style lang="scss" scoped>
.socials {
    display: inline-flex;
    margin-top: 0.5em;

    img {
        width: 60px;
    }

    .el-button {
        padding: 0;
        border: none;
    }
}
</style>
