import baseService from '../services/baseService'

export const state = () => ({
    nav: false,
    page: '',
    post: ''
})

const getters = {
    page(state) {
        return state.page.page
    },
    page_contents(state) {
        return state.page
    },
    post(state) {
        return state.post
    }
}

const actions = {
    getPage(context, params) {
        const url = params === 'index' ? 'home' : params

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                baseService
                    .get('pages/' + url)
                    .then((resp) => {
                        context.commit('set_page', resp.data)
                        resolve(resp.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }, 1000)
        })
    },
    getBlogPost(context, params) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                baseService
                    .get('pages/blog/' + params)
                    .then((resp) => {
                        context.commit('set_post', resp.data.post)
                        resolve(resp.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }, 1000)
        })
    },
    contact(context, params) {
        return new Promise((resolve, reject) => {
            baseService
                .post('contact', params)
                .then((resp) => {
                    resolve(resp.data)
                })
                .catch((error) => {
                    // error.response.data.message
                    reject(error)
                })
        })
    },
    jobs(context, params) {
        return new Promise((resolve, reject) => {
            baseService
                .post('jobs', params)
                .then((resp) => {
                    resolve(resp.data)
                })
                .catch((error) => {
                    // error.response.data.message
                    reject(error)
                })
        })
    }
}

const mutations = {
    toggle_nav(state, nav) {
        state.nav = !!nav
    },
    set_page(state, page) {
        state.page = page
    },
    set_post(state, post) {
        state.post = post
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
