import axios from 'axios'

export default {
    get(url, params = []) {
        return axios({
            url: this.constructParams(process.env.baseUrl + url, params),
            method: 'get',
            headers: this.headers(),
            responseType: 'json'
        })
    },
    post(url, params) {
        return axios({
            url: process.env.baseUrl + url,
            method: 'post',
            data: params,
            headers: this.headers()
            // ... and other options
        })
    },
    update(url, params) {
        axios({
            url: process.env.baseUrl + url,
            method: 'post',
            data: params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
            // ... and other options
        })
    },
    delete(url) {
        axios({
            url: process.env.baseUrl + url,
            method: 'delete',
            data: {},
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
            // ... and other options
        })
    },
    constructParams(url, parameters) {
        let qs = ''
        for (const key in parameters) {
            const value = parameters[key]
            qs +=
                encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&'
        }
        if (qs.length > 0) {
            qs = qs.substring(0, qs.length - 1)
            url = url + '?' + qs
        }
        return url
    },
    headers() {
        // if (localStorage.getItem('token'))
        //     return {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         Authorization: `Bearer ${localStorage.getItem('token')}`
        //     }
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }
}
