<template>
    <div id="page-view">
        <SideNav />
        <Header />

        <nuxt />

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import SideNav from '@/components/SideNav'

export default {
    components: {
        Header,
        Footer,
        SideNav
    }
}
</script>
